import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { HomeComponent } from './components/home/home.component';
import { AngularFireAuthGuard, redirectUnauthorizedTo, redirectLoggedInTo  } from '@angular/fire/auth-guard';
const redirectLoggedInToItems = () => redirectLoggedInTo(['app']);
const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['login']);


const routes: Routes = [
	{
		path: 'login',
		component: LoginComponent,
		canActivate: [AngularFireAuthGuard], 
		data: { authGuardPipe: redirectLoggedInToItems }
	},
	{
		path: 'app',
		component: HomeComponent,
		canActivate: [AngularFireAuthGuard], 
		data: { authGuardPipe: redirectUnauthorizedToLogin }
	},
	{ path: '',   redirectTo: '/app', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
