import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

	email: string;
  password: string;
  er = '';

  constructor(public auth: AngularFireAuth, private router: Router) { }

  ngOnInit() {
	 
  }
  
  login() {
	  this.auth.auth.signInWithEmailAndPassword(this.email, this.password).then(d => {
      this.router.navigate(['app']);
    }).catch(e => {
      this.er = e.message;
    })
  }

}
