import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import * as Highcharts from 'highcharts';
import { Observable, Subject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  Highcharts: typeof Highcharts = Highcharts;
  updateFromInput = false;
  chartOptionsCpu: Highcharts.Options = {
    xAxis: {
        type: 'datetime'
    },
    yAxis: {
        title: {
            text: ''
        },
        min:0,
        max:100
    },
    series: [{
      data: [],
      type: 'line'
    }],
    title: {
      text: 'CPU'
    },
    credits: {
      enabled: false
    }
  };

  chartOptionsMemory: Highcharts.Options = {
    xAxis: {
        type: 'datetime'
    },
    yAxis: {
        title: {
            text: ''
        },
        
          min:0,
          max:100
    },
    series: [{
      data: [],
      color: '#eab115',
      type: 'line'
    }],
    title: {
      text: 'Memory'
    },
    credits: {
      enabled: false
    }
  };
  beatsite$ = new Subject<string>();
  sites: Array<any> = [];
  selectedSite = '';
  status = false;
  


  constructor(private afs: AngularFirestore, public auth: AngularFireAuth, private router: Router) { }

  ngOnInit() {



    this.afs.collection('beat_sites').snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { id, ...data };
      }))
    ).subscribe(d => {
      
      this.sites = d;
      if(d.length > 0) {
        this.selectedSite = d[0].id;
        this.status = d[0].status;
        this.changeBeatSite();
      }
    }, err => {
      console.log(err);
    });
    
    const queryObservable = this.beatsite$.pipe(
      switchMap(site => 
        this.afs.collection('data', ref => ref.where('beat_id', '==', site)).snapshotChanges()
      )
    );
    queryObservable.pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { id, ...data };
      }))
    ).subscribe(d => {
      const dataCpu = [];
      const dataMemory = [];
      
        for(let i=0; i<d.length; i++) {
          const t = d[i].timestamp.toDate();
          console.log(t);
          const objCpu = [+t, d[i].cpu];
          const objMemory = [+t, d[i].memory];
          dataCpu.push(objCpu);
          dataMemory.push(objMemory);
        }

        this.chartOptionsCpu.series =  [{
          data: dataCpu,
          type: 'line'
        }];

        this.chartOptionsMemory.series =  [{
          data: dataMemory,
          type: 'line'
        }];
        this.updateFromInput = true;
        
    }, err => {
      console.log(err);
    });

    
  }

  changeBeatSite() {
    this.beatsite$.next(this.selectedSite);
  }

  logout() {
    this.auth.auth.signOut().then(d => {
        this.router.navigate(['login']);
    }).catch(err => 
      {
        console.log(err);
      })
  }



}
