// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
	  apiKey: "AIzaSyBc9kGQ6271tdbqigYkhFWUsD6bcWY6Mdk",
	      authDomain: "bank-statements-cygnet.firebaseapp.com",
	      databaseURL: "https://bank-statements-cygnet.firebaseio.com",
	      projectId: "bank-statements-cygnet",
	      storageBucket: "bank-statements-cygnet.appspot.com",
	      messagingSenderId: "1067526394696",
	      appId: "1:1067526394696:web:05f76f30ad242184f1597d"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
